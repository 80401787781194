#aboutUsSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 800px;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.aboutUsText {
    font-family: Roboto, serif;
    font-weight: 400;
    color: whitesmoke;
    margin-top: 10px;
    /*font-size: clamp(0.875rem, 0.7rem + 0.6vw, 1.125rem);*/
    font-size: clamp(0.875rem, 0.7678571428571429rem + 0.2976190476190476vw, 1.125rem);
    line-height: min(180%, 32px);
}

.contactLink:hover {
    cursor: pointer;
    color: #21ade5;
}

.counterText {
    font-family: Oswald, serif;
    font-size: clamp(3rem, 2.1268382352941178rem + 3.4926470588235294vw, 5.375rem);
    font-weight: 800;
}

.counterText2 {
    font-family: Oswald, serif;
    font-size: clamp(1rem, 0.6783088235294118rem + 1.2867647058823528vw, 1.875rem);
    font-weight: 500;
}