/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Thin.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Light.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Medium.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;
    src: url("../src/assets/fonts/Montserrat/Montserrat-Black.ttf") format('truetype');
}

/* oswald */
@font-face {
    font-family: 'Oswald';
    font-weight: 200;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-ExtraLight.ttf") format('truetype');
}
@font-face {
    font-family: 'Oswald';
    font-weight: 300;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-Light.ttf") format('truetype');
}
@font-face {
    font-family: 'Oswald';
    font-weight: 400;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-Regular.ttf") format('truetype');
}
@font-face {
    font-family: 'Oswald';
    font-weight: 500;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-Medium.ttf") format('truetype');
}
@font-face {
    font-family: 'Oswald';
    font-weight: 600;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-SemiBold.ttf") format('truetype');
}
@font-face {
    font-family: 'Oswald';
    font-weight: 700;
    font-style: normal;
    src: url("../src/assets/fonts/Oswald/Oswald-Bold.ttf") format('truetype');
}

/* roboto */
@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Thin.ttf") format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Light.ttf") format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Medium.ttf") format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Bold.ttf") format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    font-style: normal;
    src: url("../src/assets/fonts/Roboto/Roboto-Black.ttf") format('truetype');
}