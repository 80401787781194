#contactSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 300px;
    max-height: 400px;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.mainContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.contactTop {
    background: rgb(15,28,42);
    background: -moz-linear-gradient(357deg, rgba(15,28,42,1) 0%, rgba(15,15,21,1) 95%);
    background: -webkit-linear-gradient(357deg, rgba(15,28,42,1) 0%, rgba(15,15,21,1) 95%);
    background: linear-gradient(357deg, rgba(15,28,42,1) 0%, rgba(15,15,21,1) 95%);
    width: 100%;
    height: 120px;
}

.contactContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 120px);
    min-height: 560px;
    background: hsla(214, 28%, 10%, 1);
    background: linear-gradient(90deg, hsl(213, 31%, 7%) 0%, #101d2b 100%);
    background: -moz-linear-gradient(90deg, hsl(213, 31%, 7%) 0%, #101d2b 100%);
    background: -webkit-linear-gradient(90deg, hsl(213, 31%, 7%) 0%, #101d2b 100%);
    padding: 70px 0 50px 0;
    align-items: flex-start;
    justify-content: space-evenly;
}

.infoSection {
    margin-left: 16px;
    width: 100%;
}

.contactBottom {
    width: 100%;
    height: 120px;
    background: rgb(18,24,32);
    font-size: 16px;
    padding-bottom: 10px;

    background: -moz-linear-gradient(0deg, #000 0%, #0c1117 100%);
    background: -webkit-linear-gradient(0deg, #000 0%, #0c1117 100%);
    background: linear-gradient(0deg, #000 0%, #0c1117 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000",endColorstr="#121820",GradientType=1);
}

.footerText {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgb(190, 190, 190);
    padding-bottom: 12px;
    font-family: Roboto, serif;
    font-weight: 400;
}

.contactIcon {
    width: 30px !important;
    height: 30px !important;
    color: #3c4858;
    margin-right: 10px;
}