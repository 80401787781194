
#servicesSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 800px;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.servicesText {
    font-family: Roboto, serif;
    font-weight: 400;
    color: whitesmoke;
    margin-top: 50px;
    font-size: clamp(14px, 1.3vw, 18px);
    line-height: min(180%, 32px);
}