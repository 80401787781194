
html {
    scroll-behavior: smooth;
}

h1, h2, h3 {
    margin-bottom: 0;
}

.topContainer {
    /*background-image: url('../../assets/img/bg4.jpg');*/
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-color: #002236;*/
    display: flex;
    align-items: center;
    align-content: center;
    overflow: hidden;
    max-width: 100%;
    height: 100vh;
    min-height: 800px;
    background-size: cover;
}

#threeAnim > canvas {
    display: block;
    width: 100%;
    height: 100%;
    z-index:-1;
}

.topContainerContent {
    position: absolute;
    padding-top: 180px;
    padding-bottom: 50px;
    width: min(80%, 1300px);
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 800px;
    flex-direction: column;
}

.homeArrow {
    display: flex;
    align-self: center;
    z-index: 1500;
    position: relative;
    top: 180px;
}

.vignette {
    -webkit-box-shadow: inset 0 0 400px rgba(0,0,0,0.6);
    -moz-box-shadow:    inset 0 0 400px rgba(0,0,0,0.6);
    box-shadow:         inset 0 0 400px rgba(0,0,0,0.6);
    background-color: #21212b;
}

.fade{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    background: rgba(0, 0, 0, 0.44);
    min-height: 800px;
}

.gradient{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    /* http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.5+100 */
    /*background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5) 100%); !* FF3.6-15 *!*/
    /*background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5) 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 35%,rgba(0,0,0,0.6) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!*/
}

.xlText {
    font-family: Oswald, serif;
    font-size: clamp(42px, 8vw, 72px);
    font-weight: 800;
    color: #f5f5f5;
    line-height: clamp(44px, 120%, 74px);
    text-transform: uppercase;
}

.lText {
    font-family: Oswald, serif;
    font-size: 60px;
    font-weight: 600;
    color: whitesmoke;
    line-height: 68px;
}

.mText {
    font-family: Oswald, serif;
    font-size: 30px;
    font-weight: 400;
    color: #f5f5f5;
    line-height: 36px;
}

.mTextB {
    font-family: Montserrat, serif;
    font-size: 30px;
    font-weight: 600;
    color: #f5f5f5;
    line-height: 36px;
}

.sText {
    font-family: Montserrat, serif;
    font-size: 20px;
    font-weight: 500;
    color: whitesmoke;
    line-height: 20px;
}

.sTextLight {
    font-family: Helvetica, serif;
    font-size: 18px;
    font-weight: 400;
    color: whitesmoke;
    line-height: 32px;
}

.sTextDark {
    font-family: Montserrat, serif;
    font-size: 20px;
    font-weight: 300;
    color: rgb(10, 42, 55);
    line-height: 20px;
}

.sTextDarkReg {
    font-family: Montserrat, serif;
    font-size: 20px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    line-height: 20px;
}

.medSmTextB {
    font-family: Montserrat, serif;
    font-size: 26px;
    font-weight: 500;
    color: black;
    line-height: 20px;
}

.bodyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 40px;
    z-index: 2000;
    background-color: #10264c;
}

.fadeBox {
    height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 15% 20px 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centeredCol {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.centeredRow {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}


.gradientAnim {
    animation: flow 30s ease-in-out infinite;
    fill: yellow;
    background-size: 300%;
}

@keyframes flow {
    0% {
        fill: yellow;
    }

    50% {
        fill: red;
    }

    100% {
        fill: yellow;
    }
}

.contactText {
    font-family: Roboto, serif;
    font-size: clamp(14px, 1.75vw, 24px);
    font-weight: 300;
    color: rgb(190, 190, 190);
    line-height: 30px;
    display: flex;
    align-items: center;
}

.contactText a{
    color: inherit;
}

.contactText a:hover{
    color: #21ade5;
}

img {
    pointer-events: none;
    user-select: none;
}

.descriptionText {
    font-size: clamp(24px, 4vw, 36px);
    line-height: min(120%, 42px);
    text-shadow: -1px 0 10px rgba(0, 0, 0, 1);
}

#scrollToTopButton {
    min-width: 48px !important;
}

.backToTopBtn > .MuiButtonBase-root {
    z-index: 5;
}
