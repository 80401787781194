
#headerId {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    transition: all .3s ease;
    z-index: 1100;
    background-color: #000000dd;
    min-width: 360px;
}

.hide {
    top: -100px !important;
}

.headerContent {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-inline: auto;
 }

.headerLinks {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.headerLinkText {
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(245, 245, 245, 0.9);
    line-height: 20px;
    text-transform: uppercase;
}

.headerLinkText:hover{
    color: #21ade5;
}

.headerDrawer {
    z-index: 1200;
}

.linksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.drawerLink {
    font-family: Oswald, serif;
    font-size: 28px;
    font-weight: 400;
    color: rgba(245, 245, 245, 0.9);
    line-height: 32px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
}