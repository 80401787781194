#clientsSection {
    background: #0e0f14;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 740px;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.sliderContainer {
    max-height: 300px;
    width: 100%;
}

.clientsSlider {
    display: block;
    max-height: 160px !important;
}

.sliderContent .slick-arrow {
    width: 25px !important;
    height: 25px !important;
}

.slick-slide {
    display: flex !important;
    justify-content: center !important;
}

.clientCard {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 260px;
    min-width: 200px;
    max-width: 300px;
    padding: 20px;
    position: relative;
    transition: all 0.3s ease;
    margin: 6px;
    border-color: #0e0f14;
    z-index: 5;
    text-align: center;
}

.clientCard:hover {
    cursor: pointer;
    border-color: #5ba0bd;
}

.clientCard > .ant-card-body {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.clientImgContainer {
    display: flex;
    justify-content: center;
}

.clientImg {
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 5px;
}

.clientDescription {
    color: whitesmoke;
    font-size: clamp(12px, 1.1vw, 14px);
    font-weight: 400;
}

.clientTitle {
    position: absolute;
    font-family: Montserrat, serif;
    font-size: clamp(2.25rem, 1.0694rem + 2.2vw, 2.625rem);
    font-weight: 500;
    color: whitesmoke;
    line-height: 42px;
    z-index: 1000;
}

.picBackCareer {
    position: absolute;
    height: 100%;
    background-size: cover;
    right: 0;
    bottom: 0;
}

.careerText {
    font-family: Roboto, serif;
    font-weight: 400;
    color: whitesmoke;
    font-size: clamp(12px, 1.6vw, 18px);
    line-height: clamp(20px, 2.8vw, 32px);
    padding-right: min(100px, 10%);
}

.careerJoin {
    font-family: Oswald, serif;
    font-size: clamp(38px, 5vw, 60px);
    font-weight: 600;
    color: whitesmoke;
    line-height: 68px;
    white-space: nowrap;
}

.cCheckBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 65px;
}

.cCheckBox > .MuiFormControl-root > .MuiFormGroup-root > .MuiFormControlLabel-root > .MuiCheckbox-root {
    color: #21ade5;
}

.cInput {
    display: none !important;
}

.cInputButton {
    cursor: pointer;
    width: 100%;
    height: 65px;
    border-radius: 3px;
    background-color: #00000088;
    border: 1px dotted #bdbdbd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    position: relative;
}

.topUploadText {
    font-family: Roboto, serif;
    font-size: 15px;
    color: #999;
}

.bottomUploadText {
    font-family: Roboto, serif;
    font-size: 11px;
    color: #999;
}

