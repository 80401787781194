#canvasTracking {
    display: flex;
    justify-content: center;
    position: relative;
}

.gradientMaskTop {
    height: 120px;
    position: absolute;
    width: 100%;
    background: #21212b;
    background: linear-gradient(180deg, rgb(1, 4, 32) 0%, rgba(255,255,255,0) 100%);
    top: 0;
}

.gradientMaskLeft {
    height: 100%;
    min-height: 800px;
    position: absolute;
    width: min(120px, 20%);
    background: linear-gradient(90deg, rgb(1, 4, 32) 0%, rgba(255,255,255,0) 100%);
    left: 0;
    top: 0;
}

.gradientMaskRight {
    height: 100%;
    min-height: 800px;
    position: absolute;
    width: min(120px, 20%);
    background: linear-gradient(-90deg, rgb(1, 4, 32) 0%, rgba(255,255,255,0) 100%);
    right: 0;
    top: 0;
}

.gradientMaskBottom {
    height: 120px;
    position: absolute;
    width: 100%;
    background: #21212b;
    background: linear-gradient(0deg, rgb(1, 4, 32) 0%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
    bottom: 0;
}