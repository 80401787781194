#projectsSection {
    background: #0e0f14;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 800px;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.projectsTitle {
    position: relative;
    font-family: Montserrat, serif;
    font-size: clamp(2.25rem, 1.0694rem + 2.2vw, 2.625rem);
    font-weight: 500;
    color: whitesmoke;
    line-height: 42px;
    z-index: 1000;
}

.projectsContainer {
    width: 1200px;
    margin-inline: auto;
    margin-top: 0;
    position: relative;
    bottom: 50px;
}

.picBackProjects {
    position: absolute;
    height: 100%;
    background-size: cover;
    right: 0;
    bottom: 0;
}

.line {
    position: relative;
    margin-right: 16px;
    margin-left: -16px;
    bottom: 2px;
}

.projectGroup > .ant-space > .ant-space-item {
    margin-top: 12px;
    margin-bottom: 12px;
}

.projectGroup > .ant-space > .ant-space-item > .ant-radio-button-wrapper {
    background-color: transparent;
    border: none;
}

.projectGroup > .ant-space > .ant-space-item > .ant-radio-button-wrapper > .ant-radio-button-checked {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.projectGroup > .ant-space > .ant-space-item:hover > .ant-radio-button-wrapper> span > .mText {
    color: #21ade5;
}

.projectGroup > .ant-space > .ant-space-item > .ant-radio-button-wrapper-checked > span > .mText {
    color: #21ade5;
}

.projectGroup > .ant-space > .ant-space-item > .ant-radio-button-wrapper> span > .mText > .line {
    color: transparent;
}

.projectGroup > .ant-space > .ant-space-item > .ant-radio-button-wrapper-checked> span > .mText > .line {
    color: #21ade5;
}

.projectTitle {
    font-family: Oswald, serif;
    font-size: clamp(38px, 5vw, 60px);
    font-weight: 600;
    color: whitesmoke;
    line-height: 68px;
    white-space: nowrap;
}

.projectDescription {
    font-family: Roboto, serif;
    font-weight: 400;
    color: whitesmoke;
    font-size: clamp(12px, 1.6vw, 18px);
    line-height: 22px;
    min-width: 328px;
}

.projectIterator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-self: flex-end;
    min-width: 328px;
}

.whiteTitle {
    color: white;
}

.whiteTitle:visited,
.whiteTitle:active,
.whiteTitle:focus{
    color: white;
}

.whiteTitle:hover {
    color: #21ade5;
}
